export * from './features.config';
export * from './vue-meta.config';
export * from './vue-gtag.config';
export * from './vue-scrollto.config';
export * from './packages.config';

export const LOREM_IPSUM_TEXT = `
AMPLIACION Y REMODELACION DEL INMUEBLE UBICADO EN EL JR. LINO CORNEJO 106-112-124-132-140 – JR. CARABAYA 965-969-973-977-981-985 – JR. CONTUMAZA 1000-1006-1014-1018-1024
REMODELACION DEL PABELLON SANTA ROSA DEL PUERICULTORIO PEREZ ARANIBAR PABELLON DERECHO
REMODELACION DEL PABELLON DE DEPENDIENTES TOTALES EN EL HOGAR GERIATRICO SAN VICENTE DE PAUL
COSTRUCCION DE LA INFRAESTRUCTURA DE SALUD DEL PUERICULTORIO PEREZ ARANIBAR
MEJORAMIENTO Y CONSTRUCCION DEL PABELLON 09 DEL CENTRO DE ATENCION RESIDENCIAL GERONTRO GERIATRICO RODULFO VIUDA DE CANEVARO
 RESTAURACION DE LA PRIMERA CRUJIA QUE INCLUYE CASCO Y ACABADOS DEL PROYECTO DE RESTAURACION EN EL INMUEBLE DE PROPIEDAD DE LA FUNDACION, UBICADO ENTRE LOS JIRONES CARABAYA, LINO CORNEJO Y CONTUMAZA, CERCADO DE LIMA
 CONSTRUCCION DE LA NAVE 01, CASETA DE VIGILANCIA, PORTICO DE INGRESO Y CULMINACION DE TRABAJOS EN EL CERCO PERIMETRICO EN LA PLANTA COMBINADA ECOAJE (RECICLAMIENTO DE PET) - PUENTE PIEDRA.
 CONSTRUCCION DE LAS NAVES 02 Y 03 EN LA PLANTA COMBINADA ECOAJE (RECICLAMIENTO DE PET)-PUENTE PIEDRA.
§ PUESTA EN VALOR DE LA CASA MARINA NUÑEZ DEL PRADO DEL FALCON EN EL SUBSECTOR 3-3, DISTRITO DE SAN ISIDRO-LIMA-LIMA.
§ RESTAURACIÓN, REFORZAMIENTO DE TECHOS E INSTALACIONES ELÉCTRICAS DEL ASILO DE ANCIANOS SAN VICENTE DE PAÚL - CERCADO, LIMA.

`;