export type Package = Readonly<{
  package_name: string;
  description_1: string;
  description_2: string;
}>;

export const PACKAGES: Package[] = [
  {
    package_name: 'AMPLIACION Y REMODELACION DE INMUEBLES',
    description_1: `Construcciones`,
    description_2: `Drywall.`,
  },
  {
    package_name: 'COSTRUCCION DE INFRAESTRUCTURAS',
    description_1: `Metálico.`,
    description_2: `Madera`,
  },
  {
    package_name: 'RESTAURACIÓN',
    description_1: `Reparaciones.`,
    description_2: `Trabajos de restauración.`,
  },
];