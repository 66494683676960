export type Feature = Readonly<{
  description: string;
  package_name?: string;
}>;

export const FEATURES: Feature[] = [
  {
    description: 'CALLE LOS CALIFAS N° 157 URBANIZACION SALAMANCA-ATE'
  },
  {
    description: '+51-1-4083387',
    package_name: 'TEL'
  },
  {
    description: 'atenas@construcciones.biz ',
    package_name: 'email'
  },
  {
    description: '20479',
    package_name: 'RNP'
  },
  {
    description: '20521571889',
    package_name: 'RUC'
  }
];